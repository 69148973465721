import React, { useState, useEffect, useRef } from "react"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import API from "./chatBot";
import { styled } from '@mui/material/styles';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Button from '@mui/material/Button';
import useAutoScroll from "../../hooks/useAutoScroll.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-7ab6be0559/icons'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { loginRequest } from "../../config/msalConfig";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";

import backend_api from "../../utils/BackendApiCaller"
import { callMsGraph, callMsGraphTenant } from "../../utils/MsGraphApiCall";
import useAutosizeTextArea from "./useAutosizeTextArea.jsx";
import { useTheme } from "@emotion/react";
import ScrollToBottom from 'react-scroll-to-bottom';
let nextId = 0;

const MenuProps = {
    PaperProps: {
        style: {
            width: 250,
        },
    },
};

export default function BotMessage({ history, fetchMessage, updateAiResponse, downloadPDF, getBlob, getBlobURL }) {
    const [isLoading, setLoading] = useState(true)
    const [reponseObj, setReponseObj] = useState({})
    const [aiModel, setAiModel] = useState("nuwai")
    const [message, setMessage] = useState("")
    const [sources, setSources] = useState([])
    const [quick_feedback, setQuickFeedback] = useState("")
    const [summary_feedback, setSummaryFeedback] = useState("")
    const [long_feedback, setLongFeedback] = useState("")

    const [messageOption, setMessageOption] = useState("")

    const [feedbackState, setFeedbackState] = useState("")
    const [feedbackName, setFeedbackName] = useState("")
    const [feedbackEmail, setFeedbackEmail] = useState("")
    const [feedbackType, setFeedbackType] = useState("")
    const [feedback, setFeedback] = useState("")
    const [feedbackComplete, setFeedbackComplete] = useState(false)

    const [simplifyCompleted, setSimplifyCompleted] = useState(false)
    const [messageComplete, setMessageComplete] = useState(false)
    const [viewSources, setViewSources] = useState("")
    const [open, setOpen] = useState(false);
    const [snackySnack, setSnackySnack] = useState("")
    const [state, setState] = useState({
        vertical: 'top',
        horizontal: 'right',
    });
    const { vertical, horizontal } = state;

    const { instance, inProgress, accounts } = useMsal();

    const accessTokenRequestAPI = {
        scopes: [`${process.env.REACT_APP_ENTRA_APP_API}`],
        account: accounts[0],
    };

    const handleOptionChange = async (event, fileName, location) => {
        setMessageOption(event.target.value);

        var optionChoice = event.target.value;
        console.log(optionChoice)
        if (optionChoice == "viewDocument") {
            getBlob(fileName, location)
            setMessageOption("");
        } else if (optionChoice == "newTab") {
            console.log("Option Choice", optionChoice)
            var fileURL = await getBlobURL(fileName, location)
            window.open(fileURL, "_blank", 'noopener,noreferrer')
            var fileURL = ""
            setMessageOption("");

        } else if (optionChoice == "download") {
            console.log("Option Choice", optionChoice)
            console.log(fileName)
            console.log(location)
            downloadPDF(fileName, location)
            setMessageOption("");
        }
    };

    const simplifyResponse = async () => {
        setLoading(true)
        setSimplifyCompleted(true)

        if (inProgress === InteractionStatus.None) {
            callMsGraphTenant()
                .then(response => {
                    var tenantDetails = {
                        "tid": response.value[0].id,
                        "tdn": response.value[0].displayName
                    }

                    instance
                        .acquireTokenSilent(accessTokenRequestAPI)
                        .then(async (accessTokenResponse) => {
                            let accessToken = accessTokenResponse.accessToken;

                            const responseObject = await API.GetChatbotResponse("simplify", history.id, history.question, history.model, "simplify", false, history.sources, history.source_select, accessToken, tenantDetails, history, setMessage, setLoading, setReponseObj, setMessageComplete, updateAiResponse)

                            await backend_api.addResponse(accessToken, tenantDetails, history, responseObject.response, responseObject.sources, responseObject.input_tokens, responseObject.output_tokens, responseObject.total_tokens)


                        })
                        .catch((error) => {
                            if (error instanceof InteractionRequiredAuthError) {
                                instance.acquireTokenRedirect(accessTokenRequestAPI);
                            }
                            console.log(error);
                        });
                })
                .catch((e) => {
                    if (e instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({
                            ...loginRequest,
                            account: instance.getActiveAccount()
                        });
                    }
                })
        }
    }

    const handleClick = (snackValue) => {
        setSnackySnack(snackValue)
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setOpen(false);
        }
    };

    const copyMessage = e => {
        e.preventDefault();
        navigator.clipboard.writeText(message);
        handleClick("Reponse copied to clipboard");
    }

    const saveMessage = async (e) => {
        e.preventDefault();
        handleClick("Exporting response to Word Document file");
    }

    const [showAdditionalFeedback, setShowAdditionalFeedback] = useState(false)

    const postiveFeedbcak = async (e) => {
        e.preventDefault();
        setFeedbackComplete("true")
        setQuickFeedback("Good")

        callMsGraphTenant()
            .then(response => {
                var tenantDetails = {
                    "tid": response.value[0].id,
                    "tdn": response.value[0].displayName
                }

                instance
                    .acquireTokenSilent(accessTokenRequestAPI)
                    .then(async (accessTokenResponse) => {
                        let accessToken = accessTokenResponse.accessToken;

                        await backend_api.updateFeedback(accessToken, tenantDetails, history, "Good", "", "")

                        handleClick("Feedback Submitted. Thankyou.");
                    })
                    .catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect(accessTokenRequestAPI);
                        }
                        console.log(error);
                    });
            })
            .catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount()
                    });
                }
            })
    }

    const negativeFeedback = e => {
        e.preventDefault();
        setFeedbackComplete("true")
        setShowAdditionalFeedback(true)
        setQuickFeedback("Bad")

        callMsGraphTenant()
            .then(response => {
                var tenantDetails = {
                    "tid": response.value[0].id,
                    "tdn": response.value[0].displayName
                }

                instance
                    .acquireTokenSilent(accessTokenRequestAPI)
                    .then(async (accessTokenResponse) => {
                        let accessToken = accessTokenResponse.accessToken;

                        await backend_api.updateFeedback(accessToken, tenantDetails, history, "Bad", "", "")

                        handleClick("Feedback Submitted. Thankyou.");
                    })
                    .catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect(accessTokenRequestAPI);
                        }
                        console.log(error);
                    });
            })
            .catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount()
                    });
                }
            })
    }

    const [shortFeedback, setShortFeedback] = useState({
        incorrectSource: false,
        incorrectAnswer: false,
        incompleteAnswer: false
    })
    const [incorrectSourcesChecked, setIncorrectSourcesChecked] = useState(false)
    const [incorrectAnswerChecked, setIncorrectAnswerChecked] = useState(false)
    const [incompleteAnswerChecked, setIncompleteAnswerChecked] = useState(false)

    const deepFeedback = (e, value) => {
        var local_feedbackDetails = shortFeedback

        if (value == "Incorrect sources") {
            if (incorrectSourcesChecked) {
                setIncorrectSourcesChecked(false)
                local_feedbackDetails.incorrectSource = false
                setShortFeedback(local_feedbackDetails)
            } else {
                setIncorrectSourcesChecked(true)
                local_feedbackDetails.incorrectSource = true
                setShortFeedback(local_feedbackDetails)
            }
        }

        if (value == "Incorrect answer") {
            if (incorrectAnswerChecked) {
                setIncorrectAnswerChecked(false)
                local_feedbackDetails.incorrectAnswer = false
                setShortFeedback(local_feedbackDetails)
            } else {
                setIncorrectAnswerChecked(true)
                local_feedbackDetails.incorrectAnswer = true
                setShortFeedback(local_feedbackDetails)
            }
        }

        if (value == "Incomplete answer") {
            if (incompleteAnswerChecked) {
                setIncompleteAnswerChecked(false)
                local_feedbackDetails.incompleteAnswer = false
                setShortFeedback(local_feedbackDetails)
            } else {
                setIncompleteAnswerChecked(true)
                local_feedbackDetails.incompleteAnswer = true
                setShortFeedback(local_feedbackDetails)
            }
        }

        console.log("FEEDBACK ", local_feedbackDetails);
    }

    useEffect(() => {
        const loadMessage = async () => {
            if (fetchMessage) {
                try {

                    if (inProgress === InteractionStatus.None) {
                        callMsGraphTenant()
                            .then(response => {
                                var tenantDetails = {
                                    "tid": response.value[0].id,
                                    "tdn": response.value[0].displayName
                                }

                                instance
                                    .acquireTokenSilent(accessTokenRequestAPI)
                                    .then(async (accessTokenResponse) => {
                                        let accessToken = accessTokenResponse.accessToken;

                                        await backend_api.addQuestion(accessToken, tenantDetails, history)

                                        const responseObject = await fetchMessage(accessToken, tenantDetails, history, setMessage, setLoading, setReponseObj, setMessageComplete)
                                        console.log("New Response Design: ", responseObject)
                                        setAiModel(responseObject.model);

                                        await backend_api.addResponse(accessToken, tenantDetails, history, responseObject.response, responseObject.sources, responseObject.input_tokens, responseObject.output_tokens, responseObject.total_tokens)

                                        const refined_sources = responseObject.sources.filter((obj1, i, arr) =>
                                            arr.findIndex(obj2 =>
                                                ['fileName', 'linesFrom', 'linesTo'].every(key => obj2[key] === obj1[key])
                                            ) === i
                                        )

                                        setSources(refined_sources)

                                        await API.CreateTitleContext(accessToken, tenantDetails, history)

                                        if (updateAiResponse) {
                                            updateAiResponse(responseObject, history.id);
                                        }
                                    })
                                    .catch((error) => {
                                        if (error instanceof InteractionRequiredAuthError) {
                                            instance.acquireTokenRedirect(accessTokenRequestAPI);
                                        }
                                        console.log(error);
                                    });
                            })
                            .catch((e) => {
                                if (e instanceof InteractionRequiredAuthError) {
                                    instance.acquireTokenRedirect({
                                        ...loginRequest,
                                        account: instance.getActiveAccount()
                                    });
                                }
                            })
                    }
                } catch (error) {
                    console.log("Error", error);
                }
            } else {
                setSources(history.sources)
                setAiModel(history.model);
                setLoading(false)
                setMessage(history.response)
            }
        }

        if (history.id == 0 || history.type == "status") {
            setAiModel(history.model);
            setMessage(history.response);
            setLoading(false)
        } else {
            loadMessage();
        }
    }, [inProgress, accounts, instance])


    const textAreaRef = useRef(null)
    const [feedbackAdditional, setFeedbackAdditional] = useState("")
    const handleChange = evt => {
        const val = evt.target?.value

        setFeedbackAdditional(val)
    }

    const handleAdditionalFeedback = e => {
        e.preventDefault();
        setShowAdditionalFeedback(false)
        callMsGraphTenant()
            .then(response => {
                var tenantDetails = {
                    "tid": response.value[0].id,
                    "tdn": response.value[0].displayName
                }

                instance
                    .acquireTokenSilent(accessTokenRequestAPI)
                    .then(async (accessTokenResponse) => {
                        let accessToken = accessTokenResponse.accessToken;

                        await backend_api.updateFeedback(accessToken, tenantDetails, history, "Bad", shortFeedback, feedbackAdditional)

                        setFeedbackAdditional("")
                    })
                    .catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect(accessTokenRequestAPI);
                        }
                        console.log(error);
                    });
            })
            .catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount()
                    });
                }
            })
    }

    const closeAdditionalFeedback = e => {
        setShowAdditionalFeedback(false)
        setFeedbackAdditional("")
    }

    const showSources = e => {
        console.log("Show Sources")
        if(viewSources == "active") {
            setViewSources("")
        } else {
            setViewSources("active")
        }
        
    }

    useAutosizeTextArea(textAreaRef.current, feedbackAdditional)

    return (
        <>
            <div id="abacus-message-container" className={`abacus-message-container`}>
                <div className="abacus-action-container">
                    <div className="abacus-action-logo">

                    </div>
                    {messageComplete ?
                        <div className="settings">
                            <button className={`settings-btn copy`} onClick={(e) => copyMessage(e)}>Copy</button>
                            <button className={`settings-btn save`} onClick={(e) => saveMessage(e)}>Export</button>
                        </div> : <></>
                    }
                </div>
                <div className="abacus-message-blob">
                    {isLoading ?
                        <div class="skeleton-loader-container">
                            <span class="skeleton-box one"></span>
                            <span class="skeleton-box two"></span>
                            <span class="skeleton-box three"></span>
                        </div>
                        :
                        <>
                            <div className="abacus-message">
                                <Markdown remarkPlugins={[remarkGfm]}>{message}</Markdown>
                            </div>
                            {messageComplete ?
                                <>
                                    <div className="message-footer">
                                        {simplifyCompleted ? <></> :
                                            <div className="settings">
                                                <button className={`settings-btn simplify`} onClick={(e) => simplifyResponse(e)}>Simplify</button>
                                            </div>
                                        }
                                        {feedbackComplete ? <></>
                                            :
                                            <div className="feedback">
                                                <FontAwesomeIcon className={`icon thumbsUp`} icon={byPrefixAndName.fal['thumbs-up']} onClick={(e) => postiveFeedbcak(e)} />
                                                <FontAwesomeIcon className={`icon thumbsDown`} icon={byPrefixAndName.fal['thumbs-down']} onClick={(e) => negativeFeedback(e)} />
                                            </div>
                                        }
                                    </div>
                                    {history.type == "status" || sources.length == 0 ? <>
                                    </> :
                                        <div className={`abacus-sources ${viewSources}`}>
                                            <div className="abacus-sources-header">
                                                <div className="heading">
                                                    <FontAwesomeIcon className={`abacus-message-sources-icon`} icon={byPrefixAndName.fal['book-open-cover']} />
                                                    <h2>Sources</h2>
                                                </div>
                                                <div className="dropdown">
                                                    <FontAwesomeIcon className={`icon chevron-down`} icon={byPrefixAndName.fal['chevron-down']} onClick={(e) => showSources(e)} />
                                                </div>
                                            </div>
                                            <div className="abacus-sources-list-group">
                                                {sources.map((source, index) => (
                                                    <div className={`abacus-sources-list active`}>
                                                        {/* <div> */}
                                                            <p className="source-name">
                                                                {index + 1}. {source.fileName}
                                                            </p>
                                                            <p className="source-lines">
                                                                Lines: {source.linesFrom} - {source.linesTo}
                                                            </p>
                                                        {/* </div> */}
                                                        <div className="source-button-container">
                                                            <FormControl id="abacus-message-options" sx={{ m: 1, minWidth: 120 }}>
                                                                <InputLabel id="abacus-message-options-input">Options</InputLabel>
                                                                <Select
                                                                    labelId="abacus-message-options-label"
                                                                    id="abacus-message-options-select"
                                                                    value={messageOption}
                                                                    onChange={(e) => handleOptionChange(e, source.fileName, source.location)}
                                                                    autoWidth
                                                                    label="Options"
                                                                >
                                                                    <MenuItem id="aabacusi-message-options-item" value={"viewDocument"}>View document</MenuItem>
                                                                    <MenuItem id="abacus-message-options-item" value={"newTab"}>Open in new tab</MenuItem>
                                                                    <MenuItem id="abacus-message-options-item" value={"download"}>Download</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }</> :
                                <></>
                            }

                            {showAdditionalFeedback ?
                                <div className="feedback-body">
                                    <h3>What was wrong with this response? (optional)</h3>
                                    <FontAwesomeIcon className={`close-icon`} icon={byPrefixAndName.fal['xmark']} onClick={(e) => closeAdditionalFeedback(e)} />
                                    <div className="feedback-actions">
                                        <FormGroup className="feedback-actions-group">
                                            <FormControlLabel className={`feedback-btn`} control={<Checkbox checked={incorrectSourcesChecked} onChange={(e) => deepFeedback(e, "Incorrect sources")} />} label="Incorrect sources" />
                                            <FormControlLabel className={`feedback-btn`} control={<Checkbox checked={incorrectAnswerChecked} onChange={(e) => deepFeedback(e, "Incorrect answer")} />} label="Incorrect answer" />
                                            <FormControlLabel className={`feedback-btn`} control={<Checkbox checked={incompleteAnswerChecked} onChange={(e) => deepFeedback(e, "Incomplete answer")} />} label="Incomplete answer" />
                                        </FormGroup>
                                    </div>
                                    <form onSubmit={handleAdditionalFeedback} className={`input-box`}>
                                        <textarea
                                            ref={textAreaRef}
                                            rows={1}
                                            value={feedbackAdditional}
                                            placeholder="Provide additional feedback (optional)"
                                            onChange={handleChange}
                                        ></textarea>
                                        <button className={`feedback-submit `} onClick={(e) => handleAdditionalFeedback(e)}>
                                            Submit
                                        </button>
                                    </form >
                                </div>
                                : <></>
                            }
                        </>
                    }
                </div >
            </div>

            <Snackbar
                anchorOrigin={{ vertical, horizontal }
                }
                open={open}
                onClose={handleClose}
                autoHideDuration={5000}
                key={vertical + horizontal}
                message={snackySnack}
                className="snacky-bar"
            />
        </>
    )
}
